/* VISA Honors Portfolio Table */
.visa-table {
    width: 100%;
    padding: 60px;
    font-family: 'Georgia', serif;
    color: black;
}

.visa-title {
    font-size: 32px;
    margin-bottom: 40px;
}

.visa-header {
    display: flex;
    font-size: 20px;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
}

.visa-column {
    flex: 1;
    padding: 12px 0;
}

.visa-list {
    border-top: 1px solid black;
}

.visa-row {
    display: flex;
    border-bottom: 1px solid black;
    cursor: pointer;
    transition: background 0.2s ease;
}

.visa-row:hover {
    background: rgba(0, 0, 0, 0.05);
}

/* Project Detail Page */
.project-detail {
    padding: 60px;
    font-family: 'Georgia', serif;
}

.project-info {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.project-meta {
    flex: 1;
}

.project-gallery {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.detail-photo {
    width: 48%;
    height: auto;
    border: 1px solid #000;
}

.coming-soon {
    font-style: italic;
    margin-top: 20px;
}

/* Inner Child override */
.vertical-layout {
    flex-direction: column;
    align-items: flex-start;
}

.vertical-layout .project-meta {
    width: 100%;
    margin-bottom: 100px;
}

.vertical-layout .project-gallery {
    width: 100%;
    display: flex;
    justify-content: center;
}

.vertical-layout .detail-photo {
    max-width: 100%;
    height: auto;
    border: 1px solid black;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Clean back button */
.back-button {
    background: none;
    border: none;
    font-size: 16px;
    color: black;
    text-transform: lowercase;
    margin-bottom: 30px;
    cursor: pointer;
    font-family: 'Georgia', serif;
    padding: 0;
}

.back-button:hover {
    opacity: 0.5;
}

/* JUDD-STYLE layout for Inner Child */
.judd-style-layout {
    display: flex;
    flex-direction: column;
    padding: 60px;
    font-family: 'Georgia', serif;
    align-items: center;
}

.judd-style-layout .project-meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1400px;
    margin-bottom: 30px;
}

.judd-style-layout .project-meta h2,
.judd-style-layout .project-meta p {
    margin-right: 40px;
    font-size: 18px;
}

.judd-style-layout .project-gallery {
    width: 200%;
    display: flex;
    justify-content: center;
}

.judd-style-layout .detail-photo {
    max-width: 90%;
    height: auto;
    border: 1px solid black;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}