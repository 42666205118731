@viewport {
  width: device-width;
  zoom: 1;
}

@-ms-viewport {
  width: device-width;
}

body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFAFA; /* fallback for old browsers */
}

.pageTitle {
  font-size: 21px;
  margin: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100px;
}

.linkActive {
  border-radius: 20px;
}

/* Modify the button styles */
button {
  border-radius: 5px;
  padding: 8px 16px;
  border: 1px solid #000; /* Border color */
  background: transparent; /* Transparent background */
  color: black; /* Text color */
  transition: all 0.7s;
  cursor: pointer;
}

button:hover {
  opacity: 0.7;
}

.menu {
  width: 70%;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  border-bottom: 1px solid black; /* Increase the border thickness here */
  padding-bottom: 20px;
}

.navLinkText {
  padding: 10px 15px;
  text-decoration: none;
  color: black;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 23px;
}

.name {
  padding: 10px 15px;
  text-decoration: none;
  color: black;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 40px;
}

.butterfly {
  width: 50%;
  height: 30%;
  text-align: left;
}

a {
  text-decoration: none;
  color: black;
  font-size: large;
}

.hoverGeneral {
  opacity: 1;
  transition: all 0.7s;
}

.hoverGeneral:hover {
  opacity: 0.4;
}

.mainDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -50px;
}

.mainMarginDiv {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.locationButtonDiv {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.mainPictures {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.imageDiv {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center; /* Center the image vertically */
}

.photo {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  object-fit: cover; /* Ensures the image covers the entire area without distortion */
}

.generalFormat {
  margin: 100px 20%;
}

.projectImg {
  width: 46%;
  margin: 10px 2%;
}

.projectGrid {
  width: 100%;
  height: 60%;
  display: flex;
  flex-wrap: wrap;
  margin: 2% 0%;
}

.projectPopUps {
  position: fixed; /*position: fixed;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  background: rgba(0, 0, 0, 0.226);
  align-content: center;
}

.popupWhiteSquare {
  width: 40%;
  height: 50%;
  background-color: white;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 200px 30%;
  align-content: center;
}

.xDiv {
  width: 100%;
  text-align: right;
  padding-top: 10px;
}

.popUpContent {
  text-align: center;
  align-content: center;
  width: 80%;
  margin: 0px 10%;
  text-decoration: none;
}

.xText {
  padding-right: 10px;
  padding-top: 5px;
  cursor: pointer;
}

.introText {
  margin: 3% 25%;
  font-style: italic;
}

canvas {
  width: vw;
  height: vh;
  position: fixed;
  z-index: -1;
  opacity: 2;
}

.cornerDiv {
  position: absolute;
  margin-left: 10px;
  margin-top: none;
  text-decoration: none;
  font-size: 15px;
}

@media screen and (max-width: 960px) {
  .popupWhiteSquare {
    width: 80%;
    height: 80%;
    margin: 50px 10%;
  }
}

@media screen and (max-width: 600px) {
  .menu {
    width: 60%;
  }
}

@media screen and (max-width: 600px) {
  .popUpContent {
    width: 90%;
    margin: 0px 5%;
  }
}

.menu-bar {
  background: transparent; /* Transparent background */
  color: black; /* Text color for the buttons */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 20px; /* Adjust this value to add more space */
}

.content {
  text-align: center;
  max-width: 800px;
  margin-bottom: 90px;
  border-bottom: 1px solid black; /* Increase the border thickness here */
  padding-bottom: 40px;
  margin-top: 150px;
}

.my-face-image {
  width: 200px; /* Adjust the width as needed */
  height: 200px; /* Adjust the height as needed */
  border-radius: 50%;
  margin-bottom: 70px;
  border: 8px solid #000000; /* Solid black border */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); /* Slight shadow for a professional look */
}

.profile-photo {
  width: 270px;
  height: 270px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.projectGrid {
  display: flex;
  justify-content: space-between; /* Ensure space between the projects */
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.projectThumbnail {
  position: relative;
  width: 48%; /* Set width to allow two projects per row */
  display: inline-block;
  cursor: pointer;
}

.projectImg {
  width: 100%; /* Ensure the image fits within the container */
  height: 100%; /* Set a fixed height */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  object-fit: cover; /* Cover the entire area without distortion */
  transition: transform 0.5s ease;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Transparent grey overlay */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.overlayText {
  font-size: 24px;
  font-weight: bold;
}

.projectThumbnail:hover .overlay {
  opacity: 1; /* Show overlay on hover */
}

.projectThumbnail:hover .projectImg {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

